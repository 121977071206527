jQuery(document).ready(function($) {
	/* Mobile Menu */
	mobileNavigationMenuSelector = ".mobile-navigation-menu"
	menu = new Mmenu(mobileNavigationMenuSelector, {
		extensions: [
			"position-right"
		],
		navbar: {
			add: true,
			title: ""
		},
		screenReader: {
			aria: true,
			text: true
		}
	}, {
		fixedElements: {
			elemInsertMethod: "appendTo"
		}
	});

	api = menu.API;
	$(".mobile-menu-button").click(function() {
		if ($(mobileNavigationMenuSelector).hasClass("mm-menu_opened")) {
			api.close();
		}
		else {
			api.open();
		}
	});

	/* Accessible menu */
	if (navigator.userAgent.match(/iPad/i) != null) {
		$(".hasChildren > .nav-link-2").click(function(event) {
			if (!$(this).parents(".hasChildren").hasClass("open")) {
				$(".nav-link-dd-toggle-2").removeClass("open");
				event.preventDefault();
				$(this).parent(".nav-link-dd-toggle-2").addClass("open");
			}
		});
	}
	else {
		$(".nav-link-dd-toggle-2").hover(function() {
			$(this).addClass("open");
		}, function() {
			$(this).removeClass("open");
		});
	}
	$(".accesible-navigation-menu").accessibleMenu();

	// Remove image links underline
	$('.inside-page-row a > img').parent().css('border-bottom-color', 'transparent');

	/* webflow */
	/* eslint-disable */
	Webflow.require('ix').init([
		{"slug":"nav-button","name":"nav button","value":{"style":{},"triggers":[{"type":"hover","selector":".navbar-2-menu-button-icon","preserve3d":true,"stepsA":[{"title":"Spin and Fade","opacity":0.25,"transition":"transform 500ms ease 0ms, opacity 500ms ease 0ms","rotateX":"0deg","rotateY":"0deg","rotateZ":"180deg"}],"stepsB":[{"opacity":1,"transition":"transform 500ms ease 0ms, opacity 500ms ease 0ms","rotateX":"0deg","rotateY":"0deg","rotateZ":"-180deg"}]}]}},
		{"slug":"dropdown-hover-button","name":"Dropdown Hover Button","value":{"style":{},"triggers":[{"type":"hover","selector":".nav-dropdown-list","siblings":true,"stepsA":[{"display":"block"}],"stepsB":[{"display":"none"}]}]}},
		{"slug":"dropdown-hover-list","name":"Dropdown Hover List","value":{"style":{},"triggers":[{"type":"hover","stepsA":[{"display":"block"}],"stepsB":[{"display":"none"}]}]}},
		{"slug":"dropdown-hover-arrow","name":"Dropdown Hover Arrow","value":{"style":{},"triggers":[{"type":"click","selector":".nav-dropdown-list","siblings":true,"stepsA":[{"display":"block"}],"stepsB":[{"display":"none"}]}]}},
		{"slug":"desktop-nav-hover-btn","name":"Desktop Nav Hover Btn","value":{"style":{},"triggers":[{"type":"hover","selector":".desktop-nav-dropdown-list","siblings":true,"stepsA":[{"display":"block"}],"stepsB":[{"display":"none"}]}]}},
		{"slug":"desktop-nav-hover-list","name":"Desktop Nav Hover List","value":{"style":{},"triggers":[{"type":"hover","stepsA":[{"display":"block"}],"stepsB":[{"display":"none"}]}]}},
		{"slug":"mobile-dropdown","name":"mobile dropdown","value":{"style":{"display":"none"},"triggers":[{"type":"click","stepsA":[{"title":"Open mobile dropdown","display":"block"}],"stepsB":[{"title":"Close dropdown","display":"none"}]}]}},
		{"slug":"open-dropdown-mobile-menu","name":"Open dropdown mobile menu","value":{"style":{},"triggers":[{"type":"click","selector":".nav-dropdown","siblings":true,"stepsA":[{"display":"block"}],"stepsB":[{"display":"none"}]}]}}
	]);

	/* eslint-enable */
});

